import request from '@/utils/axios'

/** 获取高能经纪人列表 */
export function getAgentList(params) {
  return request({
    url: '/broker',
    method: 'get',
    params
  })
}

/** 获取礼品卡列表 */
export function getList(params) {
  return request({
    url: '/gift/card',
    method: 'get',
    params
  })
}

/** 添加礼品卡 */
export function add(data) {
  return request({
    url: '/gift/card',
    method: 'post',
    data
  })
}

/** 注销礼品卡 */
export function setCancelGiftCard(id) {
  return request({
    url: `/gift/card?id=${id}`,
    method: 'put'
  })
}

/** 导出礼品卡 */
export function giftCardExport(data) {
  return request({
    url: '/gift/card/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
