<template>
  <el-dialog
    title="新增礼品卡"
    :visible.sync="show"
    width="600px"
  >
    <el-form v-if="show" :model="form" label-width="auto">
      <el-form-item label="礼品卡类型">
        <el-select v-model="form.type" placeholder="请选择礼品卡类型" clearable @change="selectChange">
          <el-option v-for="(value, key) in type" :key="key" :value="key" :label="value" />
        </el-select>
      </el-form-item>
      <el-form-item label="绑定高能经纪人">
        <el-select v-model="form.customerId" style="width:100%;" filterable remote reserve-keyword :remote-method="getAgentList" placeholder="请输入高能经纪人UID选择高能经纪人">
          <el-option
            v-for="item in heightList"
            :key="item.id"
            :label="item.nickname"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="金币数量">
        <el-input v-model="form.points" :disabled="selectShow" />
      </el-form-item>
      <el-form-item label="礼品卡总数">
        <el-input v-model="form.total" />
      </el-form-item>
      <el-form-item label="有效期至">
        <el-date-picker
          v-model="form.expireTime"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取消</el-button>
      <el-button @click="submit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/giftCard'

export default {
  mixins: [form],
  data() {
    return {
      api,
      show: false,
      form: {
        points: ''
      },
      type: {
        1: '高能礼品卡',
        2: '商务礼品卡',
        3: '通用礼品卡'
      },
      heightList: [],
      selectShow: true
    }
  },
  methods: {
    getAgentList(query) {
      this.api.getAgentList({ page: 1, pageSize: 999, uid: query }).then(res => {
        this.heightList = res.data.list
      })
    },
    selectChange(val) {
      if (val !== '1' && val !== '2') {
        this.selectShow = false
        this.form.points = ''
      } else {
        this.selectShow = true
        this.form.points = 2000
      }
    }
  }
}
</script>
