<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-form-item>
          <el-input v-model="query.keyword" placeholder="请输入关键词" clearable />
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.deleteFlag" placeholder="请选择礼品卡状态" clearable>
            <el-option v-for="(value, key) in deleteFlag" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.sheet" placeholder="礼品卡使用情况" clearable>
            <el-option v-for="(value, key) in sheet" :key="key" :value="key" :label="value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          <el-button type="danger" icon="el-icon-refresh" @click="refresh">重置</el-button>
          <el-button type="primary" @click="derivation">导出</el-button>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="list"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
      />
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="code"
        label="兑换码"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="batch"
        label="批次"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="type"
        label="礼品卡类型"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ type[row.type] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="points"
        label="金币数量"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="nickname"
        label="高能经纪人昵称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="sheet"
        label="领取状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ sheet[row.sheet] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="deleteFlag"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ deleteFlag[row.deleteFlag] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="expireTime"
        label="有效期"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        label="操作"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button type="danger" size="small" @click="cancellation(row.id)">注销</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <Form ref="form" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/giftCard'
import Form from './form'
export default {
  components: { Form },
  mixins: [list],
  data() {
    return {
      api,
      type: {
        1: '高能礼品卡',
        2: '商务礼品卡',
        3: '通用礼品卡'
      },
      deleteFlag: {
        0: '正常',
        1: '注销',
        2: '过期'
      },
      sheet: {
        0: '未领取',
        1: '已领取'
      },
      ids: []
    }
  },
  methods: {
    // 当选择项发生变化时会触发该事件
    handleSelectionChange(val) {
      this.ids = val
    },
    cancellation(id) {
      this.$confirm('此操作将注销该礼品卡, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.setCancelGiftCard(id).then(() => {
          this.$message({
            message: '注销成功',
            type: 'success'
          })
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消注销'
        })
      })
    },
    derivation() {
      if (this.ids.length) {
        const newIds = []
        this.ids.forEach(val => {
          newIds.push(val.id)
        })
        this.api.giftCardExport({ ids: newIds }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res], { type: 'application/x-excel' }))
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.download = '礼品卡列表.xlsx'
          link.click()
          document.body.removeChild(link)
        })
      } else {
        this.$message({
          message: '请先勾选要导出的列表',
          type: 'error'
        })
      }
    }
  }
}
</script>
